.profile__container{
    border: solid 5px #808080a9;
    border-left: solid 0.3px #808080a9;
    border-top: solid 0.3px #808080a9;
    width: 70%;
    margin: auto;
    display: flex;
}

.profile__image{
    position: relative;
    margin: 2rem;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 2px solid var(--primary-color);
    .edit-image{
        padding: 0;
        position: absolute;
        bottom: 10px;
        left: 0;
        right: 0;
        margin: auto;
        svg{
            padding: 0.2rem 0.5rem;
            width: 25px;
            height: 25px;
            background: white;
        }
    }
    img{
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.profile__details{
    align-items: flex-start;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 2rem;
    div{
        display: flex;
        
        padding:0 2rem;
        p{
            width: 100px;
            
        }
        .edited{
            letter-spacing: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #008000;
            svg{
                margin-left: 0.5rem;
            }
        }
        .red{
            margin-right: 2rem;
            background: #ff0000;
        }
        input{
            width: 100%;
        }
    }

}

@media screen and (max-width:1000px) {
    .profile__container{
        width: 90%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}