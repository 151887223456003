*{
    font-family: var(--font-base);
}


span{
    font-weight: 800;
    color: var(--primary-color);
}

button{
    cursor: pointer;
}

a{
    cursor: pointer;
}

.primary-btn{
    background: var(--secondary-color);
    border-radius: 15px;
    padding: 0.5rem 1rem;
    border: none;
    color: white;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 0.2rem;
}

*{
    list-style: none;
}

.head-text{
    text-align: center;
    color: var(--primary-color);
}

select,
option,
button,
input{
    background: none;
    outline: none;
    border: none;
}

html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}