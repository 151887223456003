@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;600;700;800;900&display=swap');


:root {
    --font-base: 'Nunito', sans-serif;
    --primary-color: #6401b1;
    --secondary-color: #9132da;
  }


